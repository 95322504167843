import React, { FC } from 'react';
import { Link } from 'gatsby';

import { Language } from '../Header/models';
import { LanguageSelectorProps } from './models';

import './LanguageSelector.scss';

function getCurrentLanguage(list: Language[]): Language | undefined {
  if (typeof window !== 'undefined') {
    const current = list.find(({ code }) => code === window.location.pathname.split('/')[1]);

    return current;
  }

  return undefined;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ list }) => {
  const current = getCurrentLanguage(list);

  return (
    <div className="languageSelector">
      <div className="languageSelector__title">{current?.title}</div>
      <ul className="languageSelector__list">
        {list.map(({ title, url }) => (
          <li key={title}>
            <Link
              to={url}
              className="languageSelector__item"
              title={title}
              data-testid="language-nav-link"
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
