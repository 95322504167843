import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'gatsby';

import Image from 'common/Image';
import { LanguageSelector, LanguageSelectorPopup } from 'components/LangugageSelector';

import { HeaderProps, Language } from './models';

import './Header.scss';

interface NavItem {
  label: string;
  url: { name: string; url: string }[];
  navigationWithSubMenu?: { label: string; url: { url: string }[] }[];
}

function getCurrentLanguage(list: Language[] | undefined): Language | undefined {
  if (typeof window !== 'undefined' && list) {
    const current = list.find(({ code }) => code === window.location.pathname.split('/')[1]);

    return current;
  }

  return undefined;
}

const Header: FC<HeaderProps> = ({
  data: { logo, navs, socialMedia },
  settings: { avaliableLanguages, popupLanguage },
  alterlogo,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [activeNavItem, setActiveNavItem] = React.useState<string | null>(null);
  const currentLanguage = getCurrentLanguage(avaliableLanguages);

  const handleMouseEnter = (label: string) => {
    setActiveNavItem(label);
  };

  const handleMouseLeave = () => {
    setActiveNavItem(null);
  };

  const getNavigationUrl = (navItem: NavItem) => {
    if (navItem.navigationWithSubMenu && navItem.navigationWithSubMenu.length > 0) {
      return navItem.navigationWithSubMenu[0].url[0].url;
    }

    return navItem.url[0].url;
  };

  return (
    <header className="fixed-top">
      <div className="header mx-auto">
        {navs && navs.length > 0 ? (
          <Navbar expand="md" variant="dark" className="py-0" expanded={expanded}>
            <>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(!expanded)}
              >
                <span className="fas fa-bars" />
              </Navbar.Toggle>
              <Navbar.Collapse id="menu">
                <Nav className="header__nav">
                  {navs.map((navItem) => {
                    const hasSubMenu =
                      navItem.navigationWithSubMenu && navItem.navigationWithSubMenu.length > 0;

                    return (
                      <div
                        key={`${navItem.label}-${navItem.url[0].url}`}
                        className={`nav-item ${activeNavItem === navItem.label ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnter(navItem.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Link
                          to={getNavigationUrl(navItem)}
                          className={`text-uppercase nav-link ${hasSubMenu ? 'has-dropdown' : ''}`}
                          title={navItem.label}
                          data-testid="header-nav-link"
                          onClick={() => setExpanded(false)}
                        >
                          {navItem.label}
                        </Link>
                        {hasSubMenu && activeNavItem === navItem.label && (
                          <div className="dropdown-menu">
                            {navItem.navigationWithSubMenu.map((submenu) => (
                              <div
                                key={`${submenu.label}-${submenu.url[0].url}`}
                                className="dropdown-item"
                              >
                                <Link
                                  to={submenu.url[0].url}
                                  className="nav-link"
                                  title={submenu.label}
                                  onClick={() => setExpanded(false)}
                                >
                                  {submenu.label}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Nav>
              </Navbar.Collapse>
            </>
          </Navbar>
        ) : (
          <></>
        )}
        <div className="header__logo">
          <Navbar.Brand href={currentLanguage?.url || '/'}>
            <Image className="logo" imageData={alterlogo || logo} alt={logo?.altText || ''} />
          </Navbar.Brand>
        </div>
        {socialMedia && socialMedia.length > 0 ? (
          <Nav className="d-none d-md-flex align-items-center header__social-media">
            {socialMedia.map((mediaItem) => (
              <a
                href={mediaItem.url[0].url}
                key={mediaItem.url[0].url}
                target="_blank"
                className="nav-icon mx-1"
                data-testid="header-nav-social"
                title={mediaItem.name}
                rel="noreferrer"
              >
                <i className={`fab fa-${mediaItem.name}`} />
              </a>
            ))}
          </Nav>
        ) : (
          <></>
        )}
        {avaliableLanguages && avaliableLanguages.length > 1 ? (
          <div className={navs && navs.length > 0 ? '' : 'header__language'}>
            <LanguageSelector list={avaliableLanguages} />
            <LanguageSelectorPopup list={avaliableLanguages} title={popupLanguage} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};

export default Header;
