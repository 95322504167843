import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { FooterProps } from './models';

import './Footer.scss';

const Footer: FC<FooterProps> = ({ data: { logo, navs, contact, copyright, socialMedia } }) => (
  <footer id="footer" className="footer py-4 px-3 p-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4 text-center text-lg-start">
          <Image imageData={logo} alt={copyright} className="footer__logo mb-4" />
          <ul className="social-icon mb-4">
            {socialMedia?.map(
              ({ iconImage: { url, altText }, iconLink: { name, url: linkURL, icon } }) => (
                <li className="social-icon__item" key={name}>
                  <a
                    href={linkURL}
                    title={name}
                    data-testid="footer-nav-item"
                    className="social-icon__link"
                  >
                    <img src={url} alt={altText} className={`social-icon__image ${icon}`} />
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="col-12 col-sm-6 col-lg-4">
          <DangerouslySetInnerHtml html={contact} element="div" />
        </div>
        <div className="col-12 col-sm-6 col-lg-4">
          <ul className="list-unstyled w-100">
            {navs.map((navItem) => (
              <li className="item" key={navItem.name}>
                <Link to={navItem.url} data-testid="footer-nav-item" className="footer__link">
                  {navItem.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 mt-4 copyright">{copyright}</div>
      </div>
    </div>
  </footer>
);

export default Footer;
