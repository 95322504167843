import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { Language } from '../Header/models';
import { LanguageSelectorProps } from './models';

import './LanguageSelector.scss';

function getCurrent(list: Language[]): Language | undefined {
  if (typeof window !== 'undefined') {
    const current = list.find(({ code }) => code === window.location.pathname.split('/')[1]);

    return current;
  }

  return undefined;
}

function getShowPopup(): boolean {
  return typeof window !== 'undefined' && localStorage.getItem('language_selected') === null;
}
function setShowPopup(lang: string) {
  if (typeof window !== 'undefined') {
    localStorage.setItem('language_selected', lang);
  }
}

const LanguageSelectorPopup: FC<LanguageSelectorProps> = ({ list, title: mainTitle }) => {
  const current = getCurrent(list);
  const showPopup = getShowPopup();

  if (showPopup) {
    return (
      <div className="languageSelectorPopup popupLanguage">
        <div className="languageSelectorPopup__content">
          <div className="languageSelectorPopup__close">
            <Link
              to={current ? current.url : '/'}
              className="languageSelectorPopup__close--link"
              title={current?.title}
              onClick={() => setShowPopup('false')}
              data-testid="language-nav-link"
            >
              X
            </Link>
          </div>
          <DangerouslySetInnerHtml
            html={mainTitle || current?.title}
            element="div"
            className="languageSelectorPopup__title"
          />
          <ul className="languageSelectorPopup__list">
            {list.map(({ longTitle, title, url }) => (
              <li key={title}>
                <Link
                  to={url}
                  className="languageSelectorPopup__list--link btn--totem btn--no-arrow mx-1 mx-sm-2"
                  title={longTitle}
                  onClick={() => setShowPopup('false')}
                  data-testid="language-nav-link"
                >
                  {longTitle}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LanguageSelectorPopup;
